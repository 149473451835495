<template>
  <div>
    <loader v-if="loadingState" />
    <div class="w-75 mx-auto card pa-8" v-else>
      <div>
        <div>
          <v-text-field
            outlined
            class="w-100"
            label="Youtube Embeded Video Link"
            v-model="formData.link"
            :counter="400"
            clearable
          ></v-text-field>
          <div class="w-100">
            <iframe
              v-if="formData.link && formData.link.includes('youtube')"
              width="100%"
              height="450"
              :src="formData.link + '?autoplay=0'"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              class="iFrame"
            ></iframe>
          </div>
        </div>
        <div style="text-align: right">
          <v-btn color="primary" @click="saveButtonHander">Save</v-btn>
        </div>
      </div>

      <v-row>
        <v-col
          v-if="selectedVideosForDelete.length"
          cols="12"
          style="position:sticky; top: 0px; z-index:10; background-color:white;"
          class="card"
        >
          <div>
            {{ selectedVideosForDelete.length }} items selected
            <v-btn color="error" @click="videoRemoveFromDataArrayHandler">
              Delete?
            </v-btn>
          </div>
        </v-col>
        <v-col
          cols="6"
          v-for="(video, index) in dataArray"
          :key="index"
          class="pa-4"
        >
          <div style="position: relative; height: 100%" class="card">
            <v-checkbox
              v-model="selectedVideosForDelete"
              :value="video.id"
            ></v-checkbox>
            <div class="w-100">
              <iframe
                v-if="video.link && video.link.includes('youtube')"
                width="100%"
                height="250"
                :src="video.link + '?autoplay=0'"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="iFrame"
              ></iframe>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getData();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      selectedVideosForDelete: [],
      formData: {
        link: "",
        section: "class",
      },
      initFormData: {
        link: "",
        section: "class",
      },
      dataArray: [],
    };
  },
  methods: {
    reset() {
      this.formData = { ...this.initFormData };
      this.loadingState = false;
    },
    saveButtonHander() {
      this.loadingState = true;
      this.$http
        .post(api.VIDEO, { ...this.formData })
        .then((res) => {
          alert("saved");
          this.dataArray.push(res.data);
          this.reset();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    videoRemoveFromDataArrayHandler() {
      this.loadingState = true;
      this.$http
        .post(api.VIDEO_DELETE, { videos: this.selectedVideosForDelete })
        .then((res) => {
          this.dataArray = this.dataArray.filter(
            (e, i) => !this.selectedVideosForDelete.includes(e.id)
          );
          this.selectedVideosForDelete = []
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });

      console.log(this.selectedVideosForDelete)
    },
    getData() {
      this.loadingState = true;
      this.$http
        .get(api.VIDEO+"/class")
        .then((res) => {
          this.dataArray = res.data;
          console.log( "dataArray==>", this.dataArray)
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
