<template>
  <div>
    <loader v-if="loadingState" />
    <div class="pa-8" v-else>
      <div>
        <v-file-input
          label="Image"
          outlined
          prepend-icon=""
          accept="image/*"
          @change="fileToBase64Converter"
        ></v-file-input>
        <v-img
          :src="formData.picPath"
          v-if="formData.picPath"
          width="100%"
          height="auto"
        ></v-img>
      </div>
      <div class="mt-8">
        <tip-tap v-model="formData.description" :data="formData.description" />
      </div>
      <br />
      <div class="text-center">
        <v-btn color="success" @click="saveButtonHander">Save</v-btn>
        <v-btn color="error" @click="reset">Cancel</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import Loader from "../components/loader/loader.vue";
import TipTap from "../components/tiptap/tiptap.vue";
export default {
  beforeMount() {
    this.getDetailsData();
  },
  components: {
    TipTap,
    Loader,
  },
  computed: {},
  data() {
    return {
      file: null,
      loadingState: false,
      formData: {
        picPath: null,
        description: "",
      },
      initFormData: {
        picPath: null,
        description: "",
      },
      detailsId: this.$route.params.id,
    };
  },
  methods: {
    saveButtonHander() {
      console.log(this.formData);
      this.loadingState = true;
      this.$http
        .post(api.DETAILS, {...this.formData, submenuKey:this.detailsId})
        .then((res) => {
          
          if(res.data && res.data.description){
            this.formData = res.data;
          }
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    reset() {
      this.formData = { ...this.initFormData };
    },
    fileToBase64Converter(event) {
      if (event) {
        let reader = new FileReader();
        reader.readAsDataURL(event);
        reader.addEventListener("load", () => {
          this.formData.picPath = reader.result;
        });
      }
    },
    getDetailsData() {
      this.loadingState = true;
      this.reset()
      this.$http
        .get(api.DETAILS+"/"+this.detailsId)
        .then((res) => {
          
          if(res.data && res.data.description){
            this.formData = res.data;
          }
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });

    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    "$route.params"() {
      this.detailsId = this.$route.params.id;
      this.getDetailsData();
    },
  },
};
</script>

<style scoped></style>>
