<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else class="w-75 pa-4 ma-4 mx-auto card">
      <v-card-title>Menu and Submenu</v-card-title>
      <v-list>
        <v-list-group
          v-for="(menu, menuIndex) in menuData"
          :key="menuIndex"
          :append-icon="menu.submenus.length > 0 ? 'fa-angle-down' : null"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <!-- <v-list-item-title class="font-weight-bold" v-text="menu.name"> -->
              <v-text-field
                outlined
                dense
                hide-details
                v-model="menuData[menuIndex].name"
                label="Menu name"
              ></v-text-field>
              <!-- </v-list-item-title> -->
            </v-list-item-content>
          </template>

          <div v-if="menu.submenus" class="pl-8">
            <v-row
              v-for="(submenu, submenuIndex) in menu.submenus"
              :key="submenuIndex"
              class="d-flex justify-content-center align-items-center"
              style="margin: 5px 0"
            >
              <v-col cols="1">
                <v-checkbox
                  type="checkbox"
                  :id="submenu.name + menu.name + submenuIndex"
                  class="mr-4"
                  v-model="menuData[menuIndex].submenus[submenuIndex].isActive"
                ></v-checkbox>
              </v-col>
              <v-col cols="7">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="menuData[menuIndex].submenus[submenuIndex].name"
                  label="Submenu name"
                  :disabled="
                    !menuData[menuIndex].submenus[submenuIndex].isActive
                  "
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  v-model="menuData[menuIndex].submenus[submenuIndex].serial"
                  label="Submenu serial"
                  :disabled="
                    !menuData[menuIndex].submenus[submenuIndex].isActive
                  "
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-list-group>
      </v-list>
      <div style="text-align: right">
        <v-btn color="primary" @click="updateMenuValidity">Save</v-btn>
        <v-btn color="success" class="mx-2" @click="loadDefault">
          Load Default
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getAllMenu();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      menuData: [],
    };
  },
  methods: {
    updateMenuValidity() {
      console.log(this.menuData);
      this.loadingState = true;
      this.$http
        .put(api.MENU, { menuData: this.menuData })
        .then((res) => {
          console.log(res.data);
          window.location.reload();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    loadDefault() {},
    getAllMenu() {
      this.loadingState = true;
      this.$http
        .get(api.MENU)
        .then((res) => {
          this.menuData = res.data;
          console.log(res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
