<template>
  <div>
    <loader v-if="loadingState" />
    <v-container v-else>
      <v-row class="pa-9">
        <v-col
          cols="12"
          xl="6"
          lg="6"
          md="6"
          sm="9"
          class="mx-auto pa-9 border"
        >
          <v-card-title>Gallery Video</v-card-title>
          <v-form ref="form">
            <v-row>
              <!-- Video -->
              <v-col cols="12">
                <v-text-field
                  outlined
                  class="w-100"
                  label="Link"
                  v-model="formData.link"
                  :counter="400"
                  clearable
                ></v-text-field>
                <div class="w-100">
                  <iframe
                    v-if="formData.link"
                    width="100%"
                    height="450"
                    :src="formData.link + '?autoplay=0'"
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                    class="iFrame"
                  ></iframe>
                </div>
              </v-col>
              <!-- Buttons -->
              <v-col cols="12" class="text-center">
                <v-btn
                  :disabled="!formData.link"
                  @click="submitButtonHandler()"
                  color="success"
                >
                  Save
                </v-btn>
                <v-btn @click="reset()" color="error">
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          v-if="selectedVideosArray.length"
          style="position: sticky; top:0; left: 0; z-index: 3; background-color:wheat"
        >
          {{ selectedVideosArray.length }} items selected.

          <v-btn color="primary" @click="clearSelectedVideosArray">Clear</v-btn>
          <v-btn color="error" @click="deleteVideosBtnHandler">Delete</v-btn>
        </v-col>
        <v-col
          cols="4"
          v-for="(video, index) in gallerayVideoArray"
          :key="index"
        >
          <v-checkbox
            :value="video.id"
            v-model="selectedVideosArray"
          ></v-checkbox>
          <div class="w-100">
            <iframe
              width="100%"
              height="450"
              :src="video.link + '?autoplay=0'"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              class="iFrame"
            ></iframe>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getData();
  },
  components: {},
  computed: {},
  data() {
    return {
      formData: {
        link: "",
        section: "gallery",
      },
      initFormData: {
        link: "",
        section: "gallery",
      },
      loadingState: false,
      gallerayVideoArray: [],
      selectedVideosArray: [],
    };
  },
  methods: {
    reset() {
      this.formData = {...this.initFormData}
    },

    submitButtonHandler() {
      this.loadingState = true;
      this.$http
        .post(api.VIDEO, {
          ...this.formData,
        })
        .then((res) => {
          alert("created");
          this.gallerayVideoArray.push(res.data);
          this.reset();
        })
        .catch((error) => {
          if (error.response.status == 403) {
            alert("Invalid Link");
          } else {
            alert("error");
            console.log("Error==>", error.response.data);
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getData() {
      this.loadingState = true;
      this.$http
        .get(api.VIDEO+"/gallery")
        .then((res) => {
          this.gallerayVideoArray = res.data;
        })
        .catch((error) => {
          alert("error");
          console.log("Error==>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    clearSelectedVideosArray() {
      this.selectedVideosArray = [];
    },
    deleteVideosBtnHandler() {
      if (confirm("Do you want to procceed?")) {
        this.loadingState = true;
        this.$http
          .post(api.VIDEO_DELETE, {
            videos: this.selectedVideosArray,
          })
          .then((res) => {
            alert("deleted");
            this.gallerayVideoArray = this.gallerayVideoArray.filter(
              (e, i) => !this.selectedVideosArray.includes(e.id)
            );
            this.clearSelectedVideosArray = [];
          })
          .catch((error) => {
            alert("error");
            console.log("Error==>", error.response.data);
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
