var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.getTableName)+" "),_c('v-spacer'),(!_vm.tableMode)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","outlined":"","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('br'),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.search,"headers":_vm.getTableHeaders,"items":_vm.getTableData},scopedSlots:_vm._u([{key:"item.images",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.images.length)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.enableEdit)?_c('v-icon',{staticClass:"mr-4",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.editIconValue)+" ")]):_vm._e(),(_vm.deleteIcon)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" fa-trash-alt ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v("Reset")])]},proxy:true},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('description-modal',{attrs:{"descriptionText":item.description}})]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [_c('description-modal',{attrs:{"descriptionText":item.message}})]}},{key:"item.seenStatus",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":item.seenStatus == 1 ? 'success' : 'error'},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(_vm._s(item.seenStatus == 1 ? "Seen" : "Not Seen"))])]}},{key:"item.picPath",fn:function(ref){
var item = ref.item;
return [(item.picPath)?_c('image-modal',{attrs:{"imageSrc":item.picPath}}):_vm._e()]}},{key:"item.bannerPicPath",fn:function(ref){
var item = ref.item;
return [(item.bannerPicPath)?_c('image-modal',{attrs:{"imageSrc":item.bannerPicPath}}):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.modifiedDate(item.created_at))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }