export const fileToBase64Converter = async (file = null) => {
  return new Promise((res, rej) => {
    if (file instanceof File) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => {
        res(reader.result);
      });
    } else {
      return rej(null);
    }
  });
};
