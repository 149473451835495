<template>
  <div>
    <v-chip color="error" class="w-100" large label v-for="(error, index) in errors" :key="index">
      {{error}}
    </v-chip>
  </div>
</template>

<script>

export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  props: {
    errors:{
      type: Array,
      default:()=>[]
    }
  },
  updated() {},
  watch: {},
};
</script>

<style scoped>
</style>