<template>
  <v-container class="loader">
     <v-progress-circular
      :size="70"
      :width="7"
      indeterminate
    ></v-progress-circular>
  </v-container>
</template>

<script>

export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.loader{
  position: absolute;

  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>