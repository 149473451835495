<template>
  <div>
    <v-card-title v-if="title">{{ title }}</v-card-title>
    <tiptap-vuetify
      v-model="content"
      :extensions="extensions"
      placeholder="Write something …"
    />
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  beforeMount() {
    this.content = this.value;
  },
  components: { TiptapVuetify },
  data: () => ({
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3, 4],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    content: "",
  }),
  methods: {},
  props: {
    // Headline/Title for Component
    title: {
      type: String,
      default: "",
    },
    value: {},
  },
  watch: {
    content() {
      this.$emit("input", this.content);
    },
    value() {
      this.content = this.value;
    },
  },
};
</script>