<template>
  <div>
    <div class="d-flex">
      <v-menu
        ref="startingMenu"
        v-model="startingMenu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="250px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="eventDate"
            :label="dateLabel"
            readonly
            outlined
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          @input="$emit('input', eventDate)"
          v-model="eventDate"
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="startingMenu = false">
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.startingMenu.save(eventDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <span style="color: red">*</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import * as api from "../../config/api/frontendApi.js";
export default {
  beforeMount() {},
  components: {},
  computed: {},
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      startingMenu: false,
      eventDate: this.eventDateProps,
    };
  },
  methods: {},
  mounted() {},
  props: {
    eventDateProps: {
      default: () => new Date().toISOString().substr(0, 10),
    },
    dateLabel: {
      default: "Date",
    },
  },
  updated() {},
  watch: {
    eventDateProps() {
      this.eventDate = this.eventDateProps;
    },
  },
};
</script>

<style scoped></style>