import Vue from "vue";
import App from "./App.vue";
import vuetify from "./config/vuetify.js";
import router from "./config/router.js";

import axios from "./config/axios.js";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

import Loader from './components/loader/loader.vue'
import DataTable from './components/table/table.vue'
import Errors from './components/error/errors.vue'
Vue.component('loader', Loader);
Vue.component('DataTable', DataTable);
Vue.component('Errors', Errors);

new Vue({
  vuetify,
  router,
  render: (h) => h(App),
}).$mount("#app");
