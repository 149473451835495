<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <v-row class="pa-9">
        <v-col cols="12" class="mx-auto pa-9 border card">
          <v-form ref="form" class="">
            <v-row>
              <v-col cols="12">
                <errors :errors="errors" />
              </v-col>
              <!-- Headline -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  outlined
                  v-model="formData.headline"
                  label="Headline"
                  placeholder="Ex. Headmaster's Speech, সভাপতির বক্তব্য ... "
                  clearable
                >
                </v-text-field>
                <span style="color: red">*</span>
              </v-col>
              <!-- Name -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  outlined
                  v-model="formData.name"
                  label="Name"
                  placeholder="Name"
                  clearable
                >
                </v-text-field>
                <span style="color: red">*</span>
              </v-col>
              <!-- Serial -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  outlined
                  v-model="formData.serial"
                  label="Serial"
                  placeholder="Enter Serial No"
                  clearable
                  type="Number"
                >
                </v-text-field>
                <span style="color: red">*</span>
              </v-col>
              <!-- Image -->
              <v-col cols="12" class="d-flex">
                <div class="w-100">
                  <v-file-input
                    label="Image"
                    outlined
                    prepend-icon=""
                    accept="image/*"
                    @change="imageHandler"
                    clearable
                  ></v-file-input>
                  <div>
                    <v-img
                      v-if="formData.picPath"
                      width="100%"
                      height="200px"
                      contain
                      :src="formData.picPath"
                      style="border-radius: 5px"
                    ></v-img>
                  </div>
                </div>
                <span style="color: red">*</span>
              </v-col>
              <!-- Speech -->
              <v-col cols="12" class="d-flex">
                <TipTap
                  v-model="formData.speech"
                  :data="formData.speech"
                  class="w-100"
                />
                <span style="color: red">*</span>
              </v-col>
              <!-- Button -->
              <v-col style="text-align: right">
                <v-btn color="primary" @click="saveButtonHander">Save</v-btn>
                <v-btn color="error" @click="reset">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <data-table
            @deleteHandler="deleteHandler"
            @editHandler="editHandler"
            :tableHeaders="dataHeaderArray"
            :tableData="dataArray"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import Loader from "../components/loader/loader.vue";
import TipTap from "../components/tiptap/tiptap.vue";
import { fileToBase64Converter } from "../lib/helperFunctions.js";
export default {
  beforeMount() {
    this.getSpeechData();
  },
  components: {
    TipTap,
    Loader,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      errors: [],
      formData: {
        headline: "",
        name: "",
        picPath: "",
        speech: "",
        serial: 0,
      },
      initFormData: {
        headline: "",
        name: "",
        picPath: "",
        speech: "",
        serial: 0,
      },
      dataArray: [],
      dataHeaderArray: [
        { text: "#", value: "serial", align: "center" },
        { text: "Headline", value: "headline", align: "center" },
        { text: "Name", value: "name", align: "center" },
        { text: "Image", value: "picPath", align: "center" },
        { text: "Speech", value: "speeck", align: "center" },
        { text: "Creation Date", value: "created_at", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],
    };
  },
  methods: {
    async imageHandler(event) {
      this.formData.picPath = await fileToBase64Converter(event);
    },
    reset() {
      this.formData = { ...this.initFormData };
      this.errors = [];
      this.loadingState = false;
    },
    saveButtonHander() {
      this.loadingState = true;
      this.$http
        .post(api.SPEECH, { ...this.formData })
        .then((res) => {
          if (this.updateMode) {
            this.dataArray = this.dataArray.map((e) => {
              if (e.id == res.data.id) {
                e = res.data;
              }
              return e;
            });
          } else {
            this.dataArray.push(res.data);
          }
          console.log("Res==>", res.data);
          this.reset();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.errors = error.response.data;
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getSpeechData() {
      this.loadingState = true;
      this.$http
        .get(api.SPEECH)
        .then((res) => {
          console.log("Res==>", res.data);
          this.dataArray = res.data;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.errors = error.response.data;
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    deleteHandler(item) {
      if (confirm("Do you want to procceed?")) {
        this.loadingState = true;
        this.$http
          .delete(api.SPEECH + "/" + item.id)
          .then((res) => {
            this.dataArray = this.dataArray.filter((e) => e.id != item.id);
            console.log("Res==>", res.data);
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    editHandler(item) {
      this.updateMode = true;
      this.formData = item;
      window.scrollTo(0, 0);
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
