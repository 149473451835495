<template>
  <div>
    <v-row class="w-100">
      <loader v-if="loadingState" />
      <data-table
        v-else
        class="w-100"
        :tableHeaders="headerArray"
        :tableData="dataArray"
        @editHandler="editData"
      />
    </v-row>
  </div>
</template>

<script>
import * as api from "../config/api.js";

export default {
  beforeMount() {
    this.getAllData();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      dataArray: [],
      headerArray: [
        { text: "Name", value: "name" },
        { text: "Mail", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Message", value: "message" },
        { text: "Time", value: "created_at" },
        { text: "Seen Status", value: "seenStatus" },
      ],
    };
  },
  methods: {
    getAllData() {
      this.loadingState = true;
      this.$http
        .get(api.CONTACT_MESSAGE)
        .then((res) => {
          this.dataArray = res.data;
          console.log(res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    editData(data) {
      let seenStatus = data.seenStatus == 0 ? 1 : 0;
      if (confirm("Do you really want to procceed?")) {
        this.$http
          .post(api.CONTACT_MESSAGE + "/" + data.id, {
            seenStatus,
          })
          .then((res) => {
            data.seenStatus = seenStatus;
          })
          .catch((error) => {
            alert("error");
            console.log("Error==>", error.response.data);
          });
      }
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
