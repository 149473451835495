<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <v-row class="pa-9">
        <v-col cols="12" lg="6" md="8" sm="10" class="mx-auto pa-9 border">
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <errors :errors="errors" />
              </v-col>
              <!--  Name -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  outlined
                  v-model="formData.name"
                  label="Name"
                  placeholder="Enter  Name"
                  clearable
                >
                </v-text-field>
                <span style="color: red">*</span>
              </v-col>

              <!--  Designation -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  outlined
                  v-model="formData.designation"
                  label="Designation"
                  placeholder="Enter Designation"
                  clearable
                >
                </v-text-field>
                <span style="color: red">*</span>
              </v-col>

              <!--  Phone -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  outlined
                  v-model="formData.phone"
                  label="Phone No"
                  placeholder="Enter Phone No"
                  clearable
                >
                </v-text-field>
              </v-col>

              <!--  Serial -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  outlined
                  v-model="formData.serial"
                  label="Serial"
                  placeholder="Enter Serial"
                  clearable
                  type="Number"
                >
                </v-text-field>
                <span style="color: red">*</span>
              </v-col>

              <!-- Image -->
              <v-col cols="12">
                <div class="d-flex">
                  <v-file-input
                    label="Image"
                    outlined
                    prepend-icon=""
                    accept="image/*"
                    @change="imageHandler($event)"
                    show-size
                    clearable
                  ></v-file-input>
                </div>
                <div>
                  <v-img
                    v-if="formData.picPath"
                    width="100%"
                    height="200px"
                    contain
                    :src="formData.picPath"
                    style="border-radius: 5px"
                  ></v-img>
                </div>
              </v-col>

              <!-- Button -->
              <v-col cols="12" style="text-align: right">
                <v-btn color="primary" @click="saveButtonHander">
                  {{ updateMode ? "Update" : "Save" }}
                </v-btn>
                <v-btn color="error" @click="reset"> Cancel </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <data-table
            @deleteHandler="deleteHandler"
            @editHandler="editHandler"
            :tableHeaders="dataHeaderArray"
            :tableData="dataArray"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import { fileToBase64Converter } from "../lib/helperFunctions.js";
import DatePicker from "../components/input/datePicker.vue";
export default {
  beforeMount() {
    this.getData();
  },
  components: {
    DatePicker,
  },
  computed: {},
  data() {
    return {
      loadingState: false,
      updateMode: false,
      errors: [],
      formData: {
        name: "",
        designation: "",
        picPath: "",
        phone: "",
        serial: 0,
      },
      initFormData: {
        name: "",
        designation: "",
        picPath: "",
        phone: "",
        serial: 0,
      },
      dataArray: [],
      dataHeaderArray: [
        { text: "#", value: "serial", align: "center" },
        { text: "Name", value: "name", align: "center" },
        { text: "Image", value: "picPath", align: "center" },
        { text: "Designation", value: "designation", align: "center" },
        { text: "Phone", value: "phone", align: "center" },
        { text: "Creation Date", value: "created_at", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],
    };
  },
  methods: {
    async imageHandler(event) {
      this.formData.picPath = await fileToBase64Converter(event);
    },
    reset() {
      this.formData = { ...this.initFormData };
      this.updateMode = false;
      this.loadingState = false;
      this.errors = [];
    },
    getData() {
      this.loadingState = true;
      this.$http
        .get(api.COMMITTEE)
        .then((res) => {
          this.dataArray = res.data;
          console.log("data array==>", this.dataArray);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    saveButtonHander() {
      this.loadingState = true;
      this.$http
        .post(api.COMMITTEE, { ...this.formData })
        .then((res) => {
          if (this.updateMode) {
            this.dataArray = this.dataArray.map((e) => {
              if (e.id == res.data.id) {
                e = res.data;
              }
              return e;
            });
          } else {
            this.dataArray.push(res.data);
          }
          console.log("Res==>", res.data);
          this.reset();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.errors = error.response.data;
            window.scrollTo(0, 0);
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    deleteHandler(item) {
      this.loadingState = true;
      this.$http
        .delete(api.COMMITTEE + "/" + item.id)
        .then((res) => {
          this.dataArray = this.dataArray.filter((e) => e.id != item.id);
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    editHandler(item) {
      this.updateMode = true;
      this.formData = item;
      window.scrollTo(0, 0);
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
