<template>
  <v-dialog v-model="dialog" width="600">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="getHasDescriptionData"
        color="primary"
        small=""
        dark
        v-on="on"
        >...</v-btn
      >
      <v-btn v-else color="red" small="" dark v-on="on">...</v-btn>
    </template>
    <v-card>
      <v-card-text
        style="white-space: pre-line"
        class="pa-8 font-weight-bold"
        > 
         <span v-html="descriptionText" v-if="hasDescription"></span> 
         <span v-else>No Description Available</span> 
        </v-card-text
      >
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    getHasDescriptionData: function () {
      return this.hasDescription;
    },
    getDescriptionText: function () {
      return this.descriptionText;
    },
  },
  data() {
    return {
      dialog: false,
      hasDescription: true,
    };
  },
  props: {
    descriptionText: {
      type: String,
      default: "No Description Available",
    },
  },
  beforeMount() {
    // console.log("Text=====> '",this.descriptionText,"'")
    if (
      this.getDescriptionText == null ||
      this.getDescriptionText.length <= 1
    ) {
      this.hasDescription = false;
    }
  },
};
</script>