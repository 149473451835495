<template>
  <v-card>
    <v-card-title>
      {{ getTableName }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        outlined
        label="Search"
        single-line
        hide-details
        v-if="!tableMode"
      ></v-text-field>
    </v-card-title>
    <br />
    <v-data-table
      :search="search"
      :headers="getTableHeaders"
      :items="getTableData"
      class="elevation-1"
    >
      <!-- <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>{{getTableName}}</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template> -->

      <template v-slot:[`item.images`]="{ item }">
        {{ item.images.length }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-4" v-if="enableEdit" @click="editItem(item)">
          {{ editIconValue }}
        </v-icon>
        <v-icon v-if="deleteIcon" small class="mr-2" @click="deleteItem(item)">
          fa-trash-alt
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <description-modal :descriptionText="item.description" />
      </template>
      <template v-slot:[`item.message`]="{ item }">
        <description-modal :descriptionText="item.message" />
      </template>
      <template v-slot:[`item.seenStatus`]="{ item }">
        <v-btn
          @click="editItem(item)"
          :color="item.seenStatus == 1 ? 'success' : 'error'"
          >{{ item.seenStatus == 1 ? "Seen" : "Not Seen" }}</v-btn
        >
      </template>
      <template v-slot:[`item.picPath`]="{ item }">
        <image-modal v-if="item.picPath" :imageSrc="item.picPath" />
      </template>
      <template v-slot:[`item.bannerPicPath`]="{ item }">
        <image-modal v-if="item.bannerPicPath" :imageSrc="item.bannerPicPath" />
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ modifiedDate(item.created_at) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DescriptionModal from "./tableComponents/descriptionModal.vue";
import ImageModal from "./tableComponents/imageModal.vue";
export default {
  beforeMount() {
    // console.log("Table")
  },
  components: {
    DescriptionModal,
    ImageModal,
  },
  computed: {
    getTableData: function () {
      return this.tableData;
    },
    getTableHeaders: function () {
      return this.tableHeaders;
    },
    getTableName: function () {
      return this.tableName;
    },
  },
  data: () => ({
    search: "",
    selected: [],
  }),
  methods: {
    initialize() {},
    editItem(item) {
      this.$emit("editHandler", item);
    },
    deleteItem(item) {
      this.$emit("deleteHandler", item);
    },
    modifiedDate(date) {
      let d = new Date(date);
      //  return d.toJSON().substring(0,10)
      return d.toDateString();
    },
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableHeaders: {
      type: Array,
      default: () => [
        /* 
          { text: "Year", value: "year" },
          { text: "Name", value: "name" },
        */
      ],
    },
    tableName: {
      type: String,
      default: "",
    },
    tableMode: {
      //If True, then the search bar will not be shown
      type: Boolean,
      default: false,
    },
    editIconValue: {
      type: String,
      default: "fa-edit",
    },
    deleteIcon: {
      type: Boolean,
      default: true,
    },
    enableEdit: {
      type: Boolean,
      default: true,
    },
    "show-select": {
      type: Boolean,
      default: false,
    },
  },
};
</script>
