<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <div class="pa-8">
        <div class="mt-8">
          <tip-tap
            v-model="formData.description"
            :data="formData.description"
          />
        </div>
        <div class="mt-8">
          <v-file-input
            label="Showcase Image"
            outlined
            prepend-icon=""
            accept="image/*"
            v-model="imgStorage"
            @change="showCaseImageArrayHandler"
            multiple
          ></v-file-input>
        </div>
        <div class="mt-8">
          <v-row>
            <v-col cols="10">
              <div v-if="selectedImagesForDelete.length">
                {{ selectedImagesForDelete.length }} items selected
                <v-btn color="error" @click="imageRemoveFromImageArrayHandler">
                  Delete?
                </v-btn>
              </div></v-col
            >
            <v-col
              cols="4"
              v-for="(imgData, index) in formData.images"
              :key="index"
              class="pa-4"
            >
              <div style="position: relative; height: 100%" class="card">
                <v-checkbox
                  v-model="selectedImagesForDelete"
                  :value="index"
                ></v-checkbox>
                <div>
                  <v-img
                    v-if="imgData.picPath"
                    width="100%"
                    height="auto"
                    contain
                    :src="imgData.picPath"
                    style="border-radius: 5px"
                  ></v-img>
                </div>
                <br />

                <div style="bottom: 0px">
                  <v-textarea
                    v-model="formData.images[index].description"
                    outlined
                    label="Description"
                    placeholder="Enter Description (Optional)"
                  >
                  </v-textarea>
                </div>
                
              </div>
            </v-col>
          </v-row>
        </div>

        <br />
        <div class="text-center">
          <v-btn color="success" @click="saveButtonHander">Save</v-btn>
          <v-btn color="error" @click="reset">Cancel</v-btn>
        </div>
      </div>

      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import TipTap from "../components/tiptap/tiptap.vue";
import { fileToBase64Converter } from "../lib/helperFunctions.js";
export default {
  beforeMount() {
    
    this.getDetailsData();
  },
  components: {
    TipTap,
  },
  computed: {},
  data() {
    return {
      file: null,
      loadingState: false,
      imgStorage: [],
      formData: {
        description: "",
        images: [],
      },
      initFormData: {
        description: "",
        images: [],
      },
      selectedImagesForDelete: [],
      detailsId: this.$route.params.id,
    };
  },
  methods: {
    saveButtonHander() {
      this.loadingState = true;

      this.$http
        .post(api.CURRICULAM, { ...this.formData, submenuKey: this.detailsId })
        .then((res) => {
          this.formData = res.data;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            console.log(" Error=======>", error.response.data);
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    reset() {
      this.formData = { ...this.initFormData };
      this.selectedImagesForDelete = [];
    },
    async showCaseImageArrayHandler() {
      for (let i = 0; i < this.imgStorage.length; i++) {
        let picPath = await fileToBase64Converter(this.imgStorage[i]);
        this.formData.images.push({ picPath, description: "" });
      }
      this.imgStorage = [];
    },
    imageRemoveFromImageArrayHandler() {
      if (confirm("Do you want to remove this?")) {
        let imgIdArray = [];
        this.formData.images = this.formData.images.filter((e, i) => {
          if (this.selectedImagesForDelete.includes(i)) {
            e.id ? imgIdArray.push(e.id) : null;
          } else {
            return e;
          }
        });
        if (imgIdArray.length) {
          this.loadingState = true;
          this.$http
            .post(api.CURRICULAM_IMAGE_DELETE, { images: imgIdArray })
            .then((res) => {
              console.log(this.dataArray);
            })
            .catch((error) => {
              alert("Error Occurred");
              console.log(" Error=======>", error.response.data);
            })
            .finally(() => {
              this.loadingState = false;
            });
        }
        this.selectedImagesForDelete = [];
      }
    },
    getDetailsData() {
      this.reset();
      this.loadingState = true;
      this.$http
        .get(api.CURRICULAM + "/" + this.detailsId)
        .then((res) => {
          if (res.data && res.data.description && res.data.images) {
            this.formData = res.data;
          }
          console.log(res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    "$route.params"() {
      this.detailsId = this.$route.params.id;
      this.getDetailsData();
    },
  },
};
</script>

<style scoped></style>>
