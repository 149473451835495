<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <v-row class="mt-12">
        <v-col cols="6" class="mx-auto card pa-4">
          <v-row>
            <v-col cols="12">
              <errors :errors="errors" />
            </v-col>
            <v-col cols="12">
              <div class="d-flex">
                <v-text-field
                  v-model="formData.headline"
                  outlined
                  label="Link Headline"
                >
                </v-text-field>
                <span style="color:red">*</span>
              </div>
            </v-col>
            <v-col cols="12">
              <div class="d-flex">
                <v-text-field v-model="formData.link" outlined label="Link">
                </v-text-field>
                <span style="color:red">*</span>
              </div>
            </v-col>
            <v-col class="text-right">
              <v-btn color="success" @click="saveButtonHander">Add</v-btn>
              <v-btn color="error" @click="reset">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <data-table
            @deleteHandler="deleteHandler"
            @editHandler="editHandler"
            :tableHeaders="dataHeaderArray"
            :tableData="dataArray"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getAllImportantLinks();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      errors: [],
      updateMode: false,
      importantLinks: [],
      formData: {
        link: null,
        headline: null,
      },
      initFormData: {
        link: null,
        headline: null,
      },
      dataArray: [],
      dataHeaderArray: [
        // { text: "#", value: "id", align: "center" },
        { text: "Headline", value: "headline", align: "center" },
        { text: "Link", value: "link", align: "center" },

        { text: "Creation Date", value: "created_at", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],
    };
  },
  methods: {
    reset() {
      this.formData = { ...this.initFormData };
      this.errors = [];
      this.loadingState = false;
      this.updateMode = false;
    },
    saveButtonHander() {
      if (this.formData.link && this.formData.headline) {
        this.loadingState = true;
        this.$http
          .post(api.IMPORTANT_LINK, { ...this.formData })
          .then((res) => {
            if (this.updateMode) {
              this.dataArray = this.dataArray.map((e) => {
                if (e.id == res.data.id) {
                  e = res.data;
                }
                return e;
              });
            } else {
              this.dataArray.push(res.data);
            }
            console.log("Res==>", res.data);
            this.reset();
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              this.errors = error.response.data;
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    deleteHandler(item) {
      if (confirm("Do you want to procceed?")) {
        this.loadingState = true;
        this.$http
          .delete(api.IMPORTANT_LINK + "/" + item.id)
          .then((res) => {
            this.dataArray = this.dataArray.filter((e) => e.id != item.id);
            console.log("Res==>", res.data);
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
    editHandler(item) {
      this.updateMode = true;
      this.formData = item;
      window.scrollTo(0, 0);
    },

    getAllImportantLinks() {
      this.loadingState = true;
      this.$http
        .get(api.IMPORTANT_LINK)
        .then((res) => {
          console.log("Res==>", res.data);
          this.dataArray = res.data;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.errors = error.response.data;
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
