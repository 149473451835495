import Vue from "vue";
import VueRouter from "vue-router";
import * as slug from "./slug.js";

import Menu from "../pages/menu.vue";
import SchoolInformation from "../pages/homeSchool.vue";
import Slider from "../pages/homeSlider.vue";
import ImportantLinks from "../pages/homeImportantLinks.vue";
import Speech from "../pages/homeSpeech.vue";
import ClassVideo from "../pages/homeClassVideo.vue";

import GalleryImage from "../pages/galleryImage.vue";
import GalleryVideo from "../pages/galleryVideos.vue";

import Event from "../pages/event.vue";
import Details from "../pages/details.vue";
import Information from "../pages/information.vue";
import Notice from "../pages/notice.vue";
import Facility from "../pages/facility.vue";
import Curriculum from "../pages/curriculum.vue";
import Teacher from "../pages/teacher.vue";
import FormerHeadmaster from "../pages/formerHeadmaster.vue";
import Staff from "../pages/staff.vue";
import Committee from "../pages/committee.vue";
import ContactInformation from "../pages/contactInformation.vue";
import ContactMessage from "../pages/contactMessages.vue";

import ChangePassword from "../pages/passwordChange.vue";
import ChangeInformation from "../pages/changeUserInformation.vue";
Vue.use(VueRouter);

const route = (path, component) => {
  return { path, component };
};

const router = new VueRouter({
  mode: "history",

  routes: [
    route(slug.MENU, Menu),
    route(slug.IMPORTANT_LINKS, ImportantLinks),
    route(slug.SCHOOL_INFORMATION, SchoolInformation),
    route(slug.SLIDER, Slider),
    route(slug.SPEECH, Speech),
    route(slug.CLASS_VIDEO, ClassVideo),

    route(slug.DETAILS, Details),
    route(slug.INFORMATION, Information),
    route(slug.NOTICE, Notice),
    route(slug.FACILITY, Facility),
    route(slug.CURRICULUM, Curriculum),
    route(slug.TEACHER, Teacher),
    route(slug.FORMER_HEADMASTER, FormerHeadmaster),
    route(slug.STAFF, Staff),
    route(slug.COMMITTEE, Committee),

    route(slug.GALLERY_IMAGES, GalleryImage),
    route(slug.GALLERY_VIDEOS, GalleryVideo),
    route(slug.GALLERY_EVENTS, Event),

    route(slug.CONTACT_MESSAGE, ContactMessage),
    route(slug.CONTACT_INFORMATION, ContactInformation),

    route(slug.CHANGE_PASSWORD, ChangePassword),
    route(slug.CHANGE_INFORMATION, ChangeInformation),
    // {
    //   path: slug.FACILITY,
    //   component: Facility,
    // },
  ],
});

export default router;
