<template>
  <div>
    <loader v-if="loadingState" />
    <div class="pa-4" v-else>
      <v-row class="">
        <v-col
          class="mx-auto form_container"
          cols="12"
          xl="8"
          lg="8"
          md="8"
          sm="12"
        >
          <v-form>
            <v-row v-if="errors.length">
              <errors :errors="errors" />
            </v-row>
            <v-row>
              <!-- Map -->
              <v-col cols="12">
                <div class="d-flex">
                  <v-text-field
                    outlined
                    label="Map Link"
                    placeholder="insert map link"
                    counter="500"
                    @input="mapLinkHandler"
                  ></v-text-field>
                  <span class="text-red">*</span>
                </div>
                <div
                  v-if="formData.mapLink.includes('www.google.com/maps/embed')"
                >
                  <iframe
                    :src="formData.mapLink"
                    width="600"
                    height="450"
                    style="border: 0"
                    allowfullscreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </v-col>
              <!-- Contact Information -->
              <v-col cols="12" class="d-flex">
                <tip-tap
                  label="Contact Information"
                  placeholder="insert car contact information"
                  outlined
                  v-model="formData.contactDescription"
                ></tip-tap>
                <span class="text-red">*</span>
              </v-col>
              <!-- Button -->
              <v-col cols="12" class="text-center">
                <v-btn color="primary" @click="saveBtnHandler">Save</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import TipTap from "../components/tiptap/tiptap.vue";

export default {
  beforeMount() {
    this.getContactData();
  },
  components: { TipTap },
  computed: {},
  data() {
    return {
      loadingState: false,
      errors: [],
      formData: {
        mapLink: "",
        contactDescription: "",
      },
    };
  },
  methods: {
    mapLinkHandler(event) {
      let splitOne = event.split("width")[0];
      let splitTwo = splitOne.split("src=")[1].replace('"', "");
      this.formData.mapLink = splitTwo;
    },
    saveBtnHandler() {
      this.loadingState = true;
      this.$http
        .post(api.CONTACT_INFORMATION, { ...this.formData })
        .then((res) => {
          if (res && res.data && res.data.mapLink) {
            this.formData = res.data;
            this.errors = [];
          }
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.errors = error.response.data;
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getContactData() {
      this.loadingState = true;
      this.$http
        .get(api.CONTACT_INFORMATION)
        .then((res) => {
          if (res && res.data && res.data.mapLink) {
            this.formData = res.data;
          }
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
</style>