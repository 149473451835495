import axios from "axios";

let token = localStorage.getItem("token")
  ? JSON.parse(localStorage.getItem("token"))
  : null;

export default axios.create({
  headers: { Authorization: `Bearer ${token}` },
  params: { token },
});
