<template>
  <div>
    <loader v-if="loadingState" />
    <v-container v-else>
      <v-row class="pa-9">
        <v-col cols="12" class="mx-auto pa-9 border">
          <v-card-title>Slider Image</v-card-title>
          <v-form ref="form">
            <v-row>
              <!-- Image -->
              <v-col cols="6" class="mx-auto">
                <div class="d-flex">
                  <v-file-input
                    label="Image"
                    outlined
                    @change="imgInputHandler"
                    prepend-icon=""
                    show-size
                    multiple
                    v-model="imageArray"
                    accept="image/*"
                  ></v-file-input>
                  <span style="color: red">*</span>
                </div>
              </v-col>
              <v-col cols="12" v-if="convertedImageArrayForSave.length">
                <v-row>
                  <v-col
                    cols="4"
                    v-for="(image, index) in convertedImageArrayForSave"
                    :key="index"
                  >
                    <v-img
                      ref="displayImg"
                      width="100%"
                      height="auto"
                      :src="image.picPath"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-col>
              <!-- Buttons -->
              <v-col cols="12" class="text-center">
                <v-btn
                  :disabled="!convertedImageArrayForSave.length"
                  @click="submitButtonHandler()"
                  color="success"
                >
                  Save
                </v-btn>
                <v-btn @click="reset()" color="error">
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          v-if="selectedImagesForDelete.length"
          style="position: sticky; top:0; left: 0; z-index: 3; background-color:wheat"
        >
          {{ selectedImagesForDelete.length }} items selected.

          <v-btn color="primary" @click="clearSelectedImagesArray">Clear</v-btn>
          <v-btn color="error" @click="deleteImagesBtnHandler">Delete</v-btn>
        </v-col>
        <v-col
          cols="3"
          v-for="(img, index) in imageArrayForDisplay"
          :key="index"
        >
          <v-checkbox
            :value="img.id"
            v-model="selectedImagesForDelete"
          ></v-checkbox>
          <v-img :src="img.picPath"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import { fileToBase64Converter } from "../lib/helperFunctions.js";
export default {
  beforeMount() {
    this.getGalleryImageData();
  },
  components: {},
  computed: {},
  data() {
    return {
      imageArray: [],
      loadingState: false,
      convertedImageArrayForSave: [],
      imageArrayForDisplay: [],
      selectedImagesForDelete: [],
      section: "slider",
    };
  },
  methods: {
    reset() {
      this.imageArray = [];
      this.convertedImageArrayForSave = [];
    },
    async imgInputHandler() {
      for (let i = 0; i < this.imageArray.length; i++) {
        let picPath = await fileToBase64Converter(this.imageArray[i]);
        this.convertedImageArrayForSave.push({ picPath });
      }
      this.imageArray = [];
    },
    submitButtonHandler() {
      this.loadingState = true;
      this.$http
        .post(api.IMAGE_CREATE, {
          images: this.convertedImageArrayForSave,
          section: this.section,
        })
        .then((res) => {
          alert("created");
          this.imageArrayForDisplay = res.data;
          this.reset();
        })
        .catch((error) => {
          alert("error");
          console.log("Error==>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    getGalleryImageData() {
      this.loadingState = true;
      this.$http
        .get(api.IMAGE_GET + "/" + this.section)
        .then((res) => {
          this.imageArrayForDisplay = res.data;
          console.log("res=====>", res.data);
        })
        .catch((error) => {
          alert("error");
          console.log("Error==>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    clearSelectedImagesArray() {
      this.selectedImagesForDelete = [];
    },
    deleteImagesBtnHandler() {
      if (confirm("Do you want to procceed?")) {
        this.imageArrayForDisplay = this.imageArrayForDisplay.filter(
          (e, i) => !this.selectedImagesForDelete.includes(e.id)
        );

        this.loadingState = true;
        this.$http
          .post(api.IMAGE_DELETE, {
            images: this.selectedImagesForDelete,
          })
          .then((res) => {
            alert("deleted");
            this.selectedImagesForDelete = [];
          })
          .catch((error) => {
            alert("error");
            console.log("Error==>", error.response.data);
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
