<template>
  <div class="app_bar">
    <v-btn rounded @click="menuVisibility = !menuVisibility">
      <v-icon>fa-user</v-icon>
    </v-btn>
    <ul
      class="drop_menu_container"
      :class="{ container_append: getMenuVisibility }"
    >
      <li @click="routeTo(slug.CHANGE_PASSWORD)" class="drop_menu_list_item">
        Change Password
      </li>
      <li @click="routeTo(slug.CHANGE_INFORMATION)" class="drop_menu_list_item">
        Change Information
      </li>
    </ul>
  </div>
</template>

<script>
import * as slug from "./config/slug.js";
export default {
  beforeMount() {},
  components: {},
  computed: {
    getMenuVisibility() {
      return this.menuVisibility;
    },
  },
  data() {
    return {
      menuVisibility: false,
      slug: slug,
    };
  },
  methods: {
    routeTo(link) {
      this.$router.push(link);
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
.app_bar {
  background-color: #e9eef3;
  padding: 20px 10px;
  text-align: right;
  position: relative;
  /* height: 1500px; */
}

.drop_menu_container {
  position: absolute;
  top: 100%;
  right: -100%;
  background-color: bisque;
  padding: 20px 0px;
  padding-right: 55px;
  transition: 0.5s;
}
.container_append {
  right: 0%;
}

.drop_menu_list_item {
  list-style: none;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  text-align: left;
  padding: 10px 10px;
}

.drop_menu_list_item:hover{
  background-color: rgba(0, 0, 0, .2);
}

</style>
