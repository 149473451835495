<template>
  <div>
    <loader v-if="loadingState" />
    <div class="pa-8" v-else>
      
      <div class="mt-8">
        <tip-tap v-model="formData.description" :data="formData.description" />
      </div>
      <br />
      <div class="text-center">
        <v-btn color="success" @click="saveButtonHander">Save</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import Loader from "../components/loader/loader.vue";
import TipTap from "../components/tiptap/tiptap.vue";
export default {
  beforeMount() {
    this.getDetailsData();
  },
  components: {
    TipTap,
    Loader,
  },
  computed: {},
  data() {
    return {
      file: null,
      loadingState: false,
      formData: {
        description: "",
      },
      initFormData: {
        description: "",
      },
      detailsId: this.$route.params.id,
    };
  },
  methods: {
    saveButtonHander() {
      console.log(this.formData);
      this.loadingState = true;
      this.$http
        .post(api.INFORMATION, {...this.formData, submenuKey:this.detailsId})
        .then((res) => {
          
          if(res.data && res.data.description){
            this.formData = res.data;
          }
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    reset() {
      this.formData = { ...this.initFormData };
    },
    
    getDetailsData() {
      this.loadingState = true;
      this.reset()
      this.$http
        .get(api.INFORMATION+"/"+this.detailsId)
        .then((res) => {
          
          if(res.data && res.data.description){
            this.formData = res.data;
          }
          console.log("Res==>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
        })
        .finally(() => {
          this.loadingState = false;
        });

    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {
    "$route.params"() {
      this.detailsId = this.$route.params.id;
      this.getDetailsData();
    },
  },
};
</script>

<style scoped></style>>
