<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <v-row class="pa-9">
        <v-col cols="12" lg="6" md="8" sm="10" class="mx-auto pa-9 border">
          <v-form ref="form">
            <v-row>
              <v-col cols="12">
                <errors :errors="errors" />
              </v-col>
              <!--  Name -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  outlined
                  v-model="formData.name"
                  label="Name"
                  placeholder="Enter  Name"
                  clearable
                >
                </v-text-field>
                <span style="color: red">*</span>
              </v-col>
              <!--  Email -->
              <v-col cols="12" class="d-flex">
                <v-text-field
                  outlined
                  v-model="formData.email"
                  label="Email"
                  placeholder="Enter  Email"
                  clearable
                >
                </v-text-field>
                <span style="color: red">*</span>
              </v-col>
              <!--  Password -->
              <v-col cols="12" class="d-flex">
                <div class="w-100 text-center ">
                  <v-chip class="my-2" label color="error"
                    >Enter Your Password for Confirmation</v-chip
                  >
                  <v-text-field
                    v-model="password"
                    label="Please Enter Password for confirmation"
                    :type="passwordVisibility ? 'text' : 'password'"
                    outlined
                    :append-icon="
                      passwordVisibility ? 'fa-eye-slash' : 'fa-eye'
                    "
                    @click:append="passwordVisibility = !passwordVisibility"
                  ></v-text-field>
                </div>
              </v-col>
              <!-- Button -->
              <v-col cols="12" style="text-align: right">
                <v-btn color="primary" @click="saveButtonHander">
                  Update
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
export default {
  beforeMount() {
    this.getData();
  },
  components: {},
  computed: {},
  data() {
    return {
      loadingState: false,
      updateMode: false,
      errors: [],
      formData: {
        email: "",
        name: "",
      },
      initFormData: {
        email: "",
        name: "",
      },
      password: null,
      passwordVisibility: false,
    };
  },
  methods: {
    getData() {
      this.loadingState = true;
      this.$http
        .get(api.GET_USERINFO)
        .then((res) => {
          this.formData = res.data;
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            alert();
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    saveButtonHander() {
      if (!this.password) {
        alert("Enter Your Password for updating data");
      } else {
        this.loadingState = true;
        this.$http
          .post(api.CHANGE_USERINFO, { ...this.formData, password:this.password })
          .then((res) => {
            alert("updated");
            this.errors = [];
            this.password = null;
            console.log("Res==>", res.data);
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
            if (error.response.status == 403) {
              this.errors = error.response.data;
              window.scrollTo(0, 0);
            }
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>
