//Test(This page is only for testing component/s)
export const TEST = "/test";

export const MENU = "/home/menu";
export const SCHOOL_INFORMATION = "/home/school";
export const SLIDER = "/home/slider";
export const IMPORTANT_LINKS = "/home/links";
export const SPEECH = "/home/speech";
export const CLASS_VIDEO = "/home/class";

export const DETAILS = "/details/:id";
export const NOTICE = "/notice/:id";
export const FACILITY = "/facility/:id";
export const CURRICULUM = "/curriculum/:id";
export const INFORMATION = "/information/:id";

export const TEACHER = "/teachers";
export const FORMER_HEADMASTER = "/former_headmasters";
export const STAFF = "/staffs";
export const COMMITTEE = "/committee";

export const GALLERY_IMAGES = "/gallery/photos";
export const GALLERY_VIDEOS = "/gallery/videos";
export const GALLERY_EVENTS = "/gallery/events";

export const CONTACT_INFORMATION = "/contact/information";
export const CONTACT_MESSAGE = "/contact/messages";

export const CHANGE_PASSWORD = "/password";
export const CHANGE_INFORMATION = "/information";
