import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import '@fortawesome/fontawesome-free/css/all.css'


import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
    icons: {
        iconfont: "mdiSvg" // 'mdi' || 'mdiSvg' || 'md' || 'mdiSvg' || 'fa4' || 'fa'
    }
});

Vue.use(TiptapVuetifyPlugin, {
    vuetify, // same as "vuetify: vuetify"
    iconsGroup: "fa"
});

export default vuetify;