<template>
  <div>
    <loader v-if="loadingState" />
    <div v-else>
      <div class="pa-8">
        <!-- event type -->
        <div class="mt-8 d-flex">
          <v-autocomplete
            outlined
            label="Event Type"
            v-model="formData.submenuKey"
            :items="eventTypes"
            item-text="name"
            item-value="key"
          ></v-autocomplete>
          <span style="color: red">*</span>
        </div>
        <!-- headline -->
        <div class="mt-8 d-flex">
          <v-text-field
            outlined
            label="Headline"
            v-model="formData.headline"
            placeholder="Enter Headline..."
          ></v-text-field>
          <span style="color: red">*</span>
        </div>
        <!-- banner image -->
        <div class="mt-8 d-flex">
          <v-file-input
            label="Banner Image"
            outlined
            prepend-icon=""
            accept="image/*"
            @change="bannerImageHandler($event)"
          ></v-file-input>
          <span style="color: red">*</span>
        </div>
        <div class="mt-8 d-flex" v-if="formData.bannerPicPath">
          <v-img :src="formData.bannerPicPath"></v-img>
        </div>
        <!-- showcase images input -->
        <div class="mt-8 d-flex">
          <v-file-input
            label="Showcase Image"
            outlined
            prepend-icon=""
            accept="image/*"
            v-model="imgStorage"
            @change="showCaseImageArrayHandler"
            multiple
          ></v-file-input>
          <span style="color: red">*</span>
        </div>
        <!-- showcase image show -->
        <div class="mt-8">
          <v-row>
            <v-col cols="10">
              <div v-if="selectedImagesForDelete.length">
                {{ selectedImagesForDelete.length }} items selected
                <v-btn color="error" @click="imageRemoveFromImageArrayHandler">
                  Delete?
                </v-btn>
              </div></v-col
            >
            <v-col
              cols="4"
              v-for="(imgData, index) in formData.images"
              :key="index"
              class="pa-4"
            >
              <div style="position: relative; height: 100%" class="card">
                <v-checkbox
                  v-model="selectedImagesForDelete"
                  :value="index"
                ></v-checkbox>
                <div>
                  <v-img
                    v-if="imgData.picPath"
                    width="100%"
                    height="auto"
                    contain
                    :src="imgData.picPath"
                    style="border-radius: 5px"
                  ></v-img>
                </div>
                <br />

                <div style="bottom: 0px">
                  <v-textarea
                    v-model="formData.images[index].description"
                    outlined
                    label="Description"
                    placeholder="Enter Description (Optional)"
                  >
                  </v-textarea>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <!-- description -->
        <div class="mt-8 d-flex">
          <tip-tap
            v-model="formData.description"
            :data="formData.description"
            class="w-100"
          />
          <span style="color: red">*</span>
        </div>
        <br />
        <!-- buttons -->
        <div class="text-center">
          <v-btn color="success" @click="saveButtonHander">{{
            editMode ? "Update" : "Save"
          }}</v-btn>
          <v-btn color="error" @click="reset">Cancel</v-btn>
        </div>
      </div>

      <br />
      <br />
      <br />
      <div class="pa-8">
        <data-table
          @deleteHandler="deleteHandler"
          @editHandler="editHandler"
          :tableHeaders="dataHeaderArray"
          :tableData="dataArray"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../config/api.js";
import TipTap from "../components/tiptap/tiptap.vue";
import { fileToBase64Converter } from "../lib/helperFunctions.js";
export default {
  beforeMount() {
    this.getData();
  },
  components: {
    TipTap,
  },
  computed: {},
  data() {
    return {
      dataArray: [],
      dataHeaderArray: [
        { text: "Headline", value: "headline", align: "center" },
        { text: "Banner Image", value: "bannerPicPath", align: "center" },
        { text: "Description", value: "description", align: "center" },
        { text: "Images", value: "images", align: "center" },
        { text: "Creation Date", value: "created_at", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],
      eventTypes: [],
      file: null,
      loadingState: false,
      editMode: false,
      imgStorage: [],
      formData: {
        submenuKey: "",
        headline: "",
        bannerPicPath: "",
        description: "",
        images: [],
      },
      initFormData: {
        submenuKey: "",
        headline: "",
        bannerPicPath: "",
        description: "",
        images: [],
      },
      selectedImagesForDelete: [],
    };
  },
  methods: {
    async showCaseImageArrayHandler() {
      for (let i = 0; i < this.imgStorage.length; i++) {
        let picPath = await fileToBase64Converter(this.imgStorage[i]);
        this.formData.images.push({ picPath, description: "" });
      }
      this.imgStorage = [];
    },
    async bannerImageHandler(event) {
      this.formData.bannerPicPath = await fileToBase64Converter(event);
    },
    reset() {
      this.formData = { ...this.initFormData };
      this.selectedImagesForDelete = [];
      this.editMode = false;
    },
    saveButtonHander() {
      this.loadingState = true;
      this.$http
        .post(api.EVENT_CREATE, { ...this.formData })
        .then((res) => {
          alert("saved");
          if (this.editMode) {
            this.dataArray = this.dataArray.map((e) =>
              e.id == this.formData.id ? res.data : e
            );
          } else {
            this.dataArray.push(res.data);
          }
          this.reset();
          // console.log("data====>", res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            console.log(" Error=======>", error.response.data);
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    imageRemoveFromImageArrayHandler() {
      if (confirm("Do you want to remove this?")) {
        let imgIdArray = [];
        this.formData.images = this.formData.images.filter((e, i) => {
          if (this.selectedImagesForDelete.includes(i)) {
            e.id ? imgIdArray.push(e.id) : null;
          } else {
            return e;
          }
        });
        if (imgIdArray.length) {
          this.loadingState = true;
          this.$http
            .post(api.EVENT_IMAGE_DELETE, { images: imgIdArray })
            .then((res) => {})
            .catch((error) => {
              alert("Error Occurred");
              console.log(" Error=======>", error.response.data);
            })
            .finally(() => {
              this.loadingState = false;
            });
        }
        this.selectedImagesForDelete = [];
      }
    },
    getData() {
      this.loadingState = true;
      this.$http
        .get(api.EVENT_GET)
        .then((res) => {
          if (res.data.events && res.data.types) {
            this.dataArray = res.data.events;
            this.eventTypes = res.data.types;
          }
          console.log(res.data);
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error);
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
    editHandler(item) {
      this.formData = { ...item };
      window.scrollTo(0, 0);
      this.editMode = true;
    },
    deleteHandler(item) {
      if (confirm("Do you want to remove this?")) {
        this.loadingState = true;
        this.$http
          .delete(api.EVENT_DELETE + "/" + item.id)
          .then((res) => {
            this.dataArray = this.dataArray.filter((e) => e.id != item.id);
            alert("deleted");
            this.reset();
          })
          .catch((error) => {
            alert("Error Occurred");
            console.log(" Error=======>", error.response.data);
          })
          .finally(() => {
            this.loadingState = false;
          });
      }
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped></style>>
