<template>
  <v-dialog v-model="dialog" width="70%">
    <template v-slot:activator="{ on }">
      <v-img
        v-on="on"
        :src="imageSrc"
        height="40px"
        width="60px"
        contain
      ></v-img>
    </template>
    <v-card>
      <v-img class="mx-auto pa-8" :src="imageSrc" width="100%"></v-img>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    imageSrc: {
      type: String,
      default: "",
    },
  },
};
</script>