<template>
  <div
    style="
      width: 400px;
      border: 1px solid rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      padding: 20px;
      margin: auto;
    "
  >
    <v-card-title>Change Password</v-card-title>
    <!-- Old Password -->
    <errors :errors="errors" v-if="errors.length" />
    <v-text-field
      v-model="formData.oldPassword"
      label="Old Password"
      :type="oldPasswordVisibility ? 'text' : 'password'"
      outlined
      :append-icon="oldPasswordVisibility ? 'fa-eye-slash' : 'fa-eye'"
      @click:append="oldPasswordVisibility = !oldPasswordVisibility"
    ></v-text-field>
    <!-- New Password -->
    <v-text-field
      v-model="formData.newPassword"
      label="New Password"
      :type="newPasswordVisibility ? 'text' : 'password'"
      outlined
      :append-icon="newPasswordVisibility ? 'fa-eye-slash' : 'fa-eye'"
      @click:append="newPasswordVisibility = !newPasswordVisibility"
    ></v-text-field>
    <!-- Repeat Password -->
    <v-text-field
      v-model="formData.confirmedPassword"
      label="Confirm Password"
      :disabled="!formData.newPassword"
      :type="confirmedPasswordVisibility ? 'text' : 'password'"
      outlined
      @keyup="passwordValidation"
      :error-messages="repeatPasswordErrorMessage"
      :append-icon="confirmedPasswordVisibility ? 'fa-eye-slash' : 'fa-eye'"
      @click:append="confirmedPasswordVisibility = !confirmedPasswordVisibility"
    ></v-text-field>
    <div class="text-center mt-6">
      <v-btn
        @click="updateBtnHandler"
        :disabled="!btnValidator"
        :loading="loadingState"
        color="primary"
      >
        update password
      </v-btn>
    </div>
  </div>
</template>

<script>
import { CHANGE_PASSWORD } from "../config/api.js";
export default {
  beforeMount() {},
  components: {},
  computed: {
    btnValidator() {
      if (
        this.formData.oldPassword &&
        this.formData.newPassword &&
        this.formData.newPassword == this.formData.confirmedPassword
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      errors: [],
      loadingState: false,
      oldPasswordVisibility: false,
      newPasswordVisibility: false,
      confirmedPasswordVisibility: false,
      formData: {
        oldPassword: null,
        newPassword: null,
        confirmedPassword: null,
      },
      initFormData: {
        oldPassword: null,
        newPassword: null,
        confirmedPassword: null,
      },
      repeatPasswordErrorMessage: null,
    };
  },
  methods: {
    reset() {
      this.formData = { ...this.initFormData };
      this.errors = [];
    },
    passwordValidation(event) {
      if (this.formData.newPassword != this.formData.confirmedPassword) {
        this.repeatPasswordErrorMessage = "Password is not matched";
      } else {
        this.repeatPasswordErrorMessage = null;
      }
    },
    updateBtnHandler() {
      this.loadingState = true;
      this.$http
        .post(CHANGE_PASSWORD, { ...this.formData })
        .then((res) => {
          alert("saved");
          console.log("Res==>", res.data);
          this.reset();
        })
        .catch((error) => {
          alert("Error Occurred");
          console.log(" Error=======>", error.response.data);
          if (error.response.status == 403) {
            this.errors = error.response.data;
            console.log(" Error=======>", this.errors);
          }
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {},
  props: {},
  updated() {},
  watch: {},
};
</script>

<style scoped>
</style>